import React, { useMemo } from 'react';
import moment from 'moment';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

interface AreaChartProps {
  data: Array<{
    _time: string;
    _value: number;
    _unit: string;
    _variableName: string;
  }>;
}

// Define the DataPoint interface here
type DataPoint = { time: string } & { [key: string]: number | string };

const generateColor = (index: number) => {
  const hue = index * 137.508;
  return `hsl(${hue}, 50%, 60%)`;
};

interface ProcessedDataResult {
  transformedData: DataPoint[];
  variables: string[];
  minTime: number;
  maxTime: number;
}

// Adjusted processData function
const processData = (data: AreaChartProps['data']): ProcessedDataResult => {
  const groupedData = data.reduce((acc, d) => {
    const timeFormatted = moment(d._time).format('DD/M HH:mm');
    const cleanVariableName = d._variableName.substring(d._variableName.indexOf('_') + 1);
    const variableNameWithUnit = `${cleanVariableName} (${d._unit})`;

    if (!acc[timeFormatted]) {
      acc[timeFormatted] = { time: timeFormatted } as DataPoint;
    }

    // Handle missing values:
    acc[timeFormatted][variableNameWithUnit] =
      d._value !== undefined
        ? Number(d._value.toFixed(1)) // If value exists, use it
        : -1; // If missing, replace with a placeholder value (-1)

    return acc;
  }, {} as { [key: string]: DataPoint });

  const transformedData = Object.values(groupedData);
  // Map the variables to include the unit in the name, after cleaning up the variable name
  const variables = Array.from(new Set(data.map(item => {
    const cleanVariableName = item._variableName.substring(item._variableName.indexOf('_') + 1);
    return `${cleanVariableName} (${item._unit})`;
  })));

  const maxTime = moment(data[data.length - 1]._time).valueOf();
  const minTime = moment(maxTime).subtract(24, 'hours').valueOf();

  return {
    transformedData,
    variables,
    minTime,
    maxTime,
  };
};


interface CustomTickProps {
  x: number;
  y: number;
  stroke?: string;
  payload: {
    value: any; // Adjust according to what your payload actually contains
  };
}

const CustomTick: React.FC<CustomTickProps> = ({ x, y, stroke = "#666", payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="middle" fill={stroke}>
        {payload.value}
      </text>
    </g>
  );
};


const AreaChart: React.FC<AreaChartProps> = ({ data }) => {
  const { transformedData, variables, minTime, maxTime } = useMemo(() => {
    if (!data || data.length === 0) {
      return { transformedData: [], variables: [], minTime: 0, maxTime: 0 };
    }
    return processData(data);
  }, [data]);


  return (
    <ResponsiveContainer width="100%" height={600}>
      <LineChart
        data={transformedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="time" 
          type="category"
          allowDuplicatedCategory={false}
          
        />
        <YAxis allowDecimals={true} />
        <Tooltip
            contentStyle={{
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              border: 'none',
              color: 'white',
              borderRadius: '8px', // Add corner rounding
            }}
          
          />

        <Legend formatter={(value) => value.split('_').join(' ').toUpperCase()} />
        {variables.map((variableWithUnit, idx) => (
          <Line
            key={idx}
            type="monotone"
            dataKey={variableWithUnit}
            stroke={generateColor(idx)} // Random color
            dot={false}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AreaChart;
