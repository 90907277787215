import React, { useEffect, useState } from 'react';
import { fetchData } from './API/apiService'; // Adjust the import path as necessary
import AreaChart from './Chart/AreaChart'; // Adjust the import path as necessary

import './DarkTheme.css'; // Import the dark theme CSS

const App = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchData();
      setData(fetchedData);
      console.log(data);
    };
    loadData();
  }, []);

  return <div>
    <AreaChart data={data} />
  </div>;
};

export default App;
